import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const MenPage = () => (
  <Layout>
    <Seo title="OC Beach Wax for Men" description="OC Beach Wax is located in Ocean City, MD, and offers a variety of services for men including waxing and facials." />
    <div className="row justify-content-center page-header">
        <div className="stripers">&nbsp;</div>
        <div className="col-11 col-md-4 col-xl-3">
            <h1>Waxing &amp; Facials for Men</h1>
            <p>Male waxing and facials are two popular grooming services that are tailored specifically for men.</p>
        </div>
        <div className="col-11 col-md-8 col-lg-6 col-xl-5">
        <StaticImage      
            className="collage-img-wrap"
            imgClassName="collage-img img-fluid"
            loading="eager" 
            alt="waxed men on the beach"
            src={
            "../images/men-collage1.png"
            }
            objectFit="contain"        
            objectPosition="50% 50%"
            
        />
        </div>
    </div>
    <div className="row justify-content-center page-content">
        <div className="col-12 col-md-7 page-section">
            <div className="page-section-text">                
                <h3>Male Waxing</h3>   
                <p>Male waxing involves the removal of body hair using wax. The most common areas for male waxing include the back, chest, 
                    arms, legs, underarms, and private areas. </p>
                    <p>Our team has a lot of experience working with male clients. 
                    We use appropriate waxing techniques and products to ensure efficient and relatively comfortable hair removal. 
                    Although we'll be sure to ask, please make sure we know if there's a certain area or level of removal you're looking for.
                    </p>
                <h3>Facials for Men</h3>
                <p>Facials for men are customized skincare treatments designed to address specific concerns and improve the overall condition of the skin. 
                    The most common features or types of facials for men include cleansing, exfoliation, moisturization, and facial massages.</p>
                    <p>We use products that are formulated to address male-specific skin concerns and cater to the unique needs of men's skin.</p>
               
            </div>
        </div>
        <div className="col-12 col-md-7 col-lg-5 page-section">
            <div className="pricing">
                <h2>Waxing</h2>                   
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Arm Full</div><div className="col-3 col-lg-2 ">40</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Arm Half</div><div className="col-3 col-lg-2 ">35</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Back Full</div><div className="col-3 col-lg-2 ">60+</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Back Half</div><div className="col-3 col-lg-2 ">30+</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Back, Neck, &amp; Shoulders</div><div className="col-3 col-lg-2 ">65+</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Brazilian</div><div className="col-3 col-lg-2 ">80+</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Chest</div><div className="col-3 col-lg-2 ">40</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Eyebrow</div><div className="col-3 col-lg-2 ">18</div></div>                     
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Feet and Toes</div><div className="col-3 col-lg-2 ">10</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Legs Full</div><div className="col-3 col-lg-2 ">75+</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Legs Half</div><div className="col-3 col-lg-2 ">45</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Neck/Shoulders</div><div className="col-3 col-lg-2 ">20</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Patch</div><div className="col-3 col-lg-2 ">15</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Sideburns</div><div className="col-3 col-lg-2 ">15</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Underarms</div><div className="col-3 col-lg-2 ">25</div></div>
                <h2>Facials</h2>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Anti-Aging Facial</div><div className="col-3 col-lg-2 ">85/65</div></div>
                <div className="row justify-content-center align-items-center"><div className="col-9 col-md-8 col-lg-7">Seasonal Skin Facial</div><div className="col-3 col-lg-2 ">75/55</div></div>                                   
                        
            </div>
      </div>
    </div>
    
  </Layout>
)

export default MenPage
